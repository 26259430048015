import React,  { useState } from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "../layouts"
import Seo from "../components/seo"
import {
	BreadCrumb,
	FlexWrap,
	RightWrap,
	LeftWrap,
	SectionPageTitle,
	SectionDescription,
  MarkdownContent
} from "../components/Section"
import FinancingCheckIcon from "../components/Icons/FinancingCheckIcon";
import WidthIcon from "../components/Icons/WidthIcon"
import HeightIcon from "../components/Icons/HeightIcon"
import LengthIcon from "../components/Icons/LengthIcon"
import WarrantyCard from '../components/WarrantyCard';
import PanelCard from '../components/PanelCard';
import ApplyFinancingCard from '../components/ApplyFinancingCard';
import GetCustomQuoteForm from '../components/Forms/GetCustomQuote';
import ProductButtons from '../components/ProductButtons';

const HeroWrap = styled(FlexWrap)`
  @media(max-width:991px) {
    flex-direction: column-reverse;
  }
`

const ImageWrap = styled.div`
	position: relative;
  z-index: 9;
	width: 100%;
  margin-top: 30px;
  height: auto;

	.gatsby-image-wrapper {
		position: relative;
		width: 100%;
    height: 100%;
		border-radius: 6px;

    div {
      max-width: 100% !important;
    }
	}

  @media(max-width: 996px) {
    margin-bottom: 50px;
  }

`
const SkuWrap = styled.div`
	position: absolute;
	z-index: 2;
	top: -10px;
	left: -22px;
	width: 180px;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
	.sku {
		position: absolute;
		top: 8px;
		left: 30px;
		width: 150px;
		font-size: 14px;
		line-height: 19px;
		color: white;
		text-transform: uppercase;
	}

  @media(max-width: 480px) {
    left: -16px;
  }
`

const PricingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0 15px;
  margin: 20px 0;
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  @media(max-width: 600px) {
    display: block;

    .financing-wrap {
      margin-top: 30px;
      justify-content: flex-start;
    }
  }
`



const FeatureHeader = styled.h2`
  position: relative;
  z-index: 1;
  font-size: 30px;
  line-height: 45px;
  color: #000000;
  padding: 7px 80px 7px 30px;
  border-left: 6px solid #E43D3C;
  background: #F7F5F5;
  width: fit-content;
  margin: 60px 0 30px;

  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    width: 0px;
    height: 0px;
    top: 1px;
    right: -135px;
    box-sizing: border-box;
    border: 40px solid #fff;
    transform-origin: 0px 0px;
    transform: rotate(135deg);
  }
  @media(max-width: 767px) {
    margin-top: 40px;
    overflow: hidden;
  }
`

const FeaturesWrap = styled.div`
  display: flex;

  @media(max-width: 767px) {
    flex-wrap: wrap;
  }
`

const Features = styled.div`
  margin: 15px 0 0;
  ul{
    padding:0;
    margin:0 0 15px;
    list-style-position: inside;
    list-style-type: disc;
    color:#666;  
    li{
      + li{
        margin-top: 15px;
      }
    }  
  }
  li {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 26px;
    @media (min-width: 1600px) {
      font-size: 18px;
      line-height: 32px;
    }
    
    p {
      width: calc(100% - 40px);
      display: inline-block;
      vertical-align: text-top;
      margin-bottom: 0;
    }
    &::marker {
      color: #E43D3C;
    }
  }
`

const DimensionPrice = styled.div`
  font-size: 16px;
  line-height: 1;
  padding: 12px 20px;
  margin: 0 20px 16px 0;
  background: #F7F5F5;
  border: 1px solid #D0D0D0;
  border-radius: 50px;
  color: #574C4C;
`

const ColorOptions = styled.div`
  margin-top: 60px;
  background: #F7F5F5;
  padding: 48px 20px 0;
  margin-bottom: 50px;
  @media(max-width: 480px) {
    padding: 48px 10px 0;
  }
`
const ColorOptionTabsWrap = styled.div`
  display: flex;
  background: #F7F5F5;
  border: 1px solid #D0D0D0;
  border-radius: 40px;
  padding: 3px;
  margin-top: -75px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`

const ColorOptionTab = styled.div`
  position: relative;
  background: ${props => (props.active === "true" ? "#ffffff" : "#F7F5F5")};
  color: ${props => (props.active === "true" ? "#E43D3C" : "#938888")};
  box-shadow: ${props => (props.active === "true" ? "0 0 30px rgba(0, 0, 0, .16)" : "none")};
  border-radius: 40px;
  font-size: 20px;
  line-height: 1;
  padding: 11px 20px;
  margin-left: ${props => props.ml};

  :hover {
    cursor: pointer;
  }

  @media(max-width: 480px) {
    font-size: 14px;
    padding: 10px 15px;
  }
`

const ColorsWrap = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .color {
    width: 23%;
    background: #ffffff;
    padding: 0 10px 10px 10px;
    margin-bottom: 30px;
    text-align: center;

    .gatsby-image-wrapper {
      width: 100%;
      height: 40px;
    }

    p {
      font-size: 14px;
      line-height: 1;
      margin: 5px 0 0 0;
    }
    @media(max-width: 480px) {
      padding: 0 5px 10px;
      p {
        font-size: 12px;
      }
    }
  }
`

const DimensionsWrap = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  padding: 5px 20px 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  margin: auto;
  margin-top: -35px;
  width: fit-content;

  .dimension {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;

    .label {
      font-size: 10px;
      line-height: 14px;
      margin-bottom: 10px;
    }
    .h5, h5{
      display:block;
      font-size: 15px;
      line-height: 1;
      margin: 0;
    }
    svg {
      margin-right: 3px;
      margin-bottom: -15px;
    }
  }
  @media(max-width: 992px) {
    margin-top: -85px;
  }
  @media(max-width: 380px) {
    width: 100%;
  }
`

const CallPrice = styled.a`
  font-size: 24px;
  color: #000000;

  :hover {
	cursor: pointer;
	text-decoration: underline;
	color: #E43D3C;
  }
`
const FeatureItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  @media(min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  ul{
    margin-bottom: 0;
  }
`

const ProductTemplate = ({ data, location }) => {
  const pageData = data.contentfulProduct
  // const PostContent = HTMLContent || Content;
  const [colorOption, setColorOption] = useState("siding");

  const schema = { 
    "@context": "https://schema.org/",  
    "@type": "Product",  
    "name": pageData.name,
    "url": pageData.url,
    "image":pageData.image.file.url,
    "description": pageData.description.description,
    "brand": { 
      "@type": "Brand", 
      "name": "Buildings And More" 
    }, 
    "sku": pageData.sku, 
    "aggregateRating": { 
      "@type": "AggregateRating", 
      "ratingValue": "4.8", 
      "ratingCount": "228" 
    } 
  } 
  //console.log(schema, 'Schema Test');
  return(
    <Layout footerCta location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription}  schemaMarkup={schema} />
      <BreadCrumb position="relative">
        <div className="container">
          <Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
          <span>{pageData.name}</span>
        </div>
      </BreadCrumb>
      <div className="container no-mobile-padding">
      	<HeroWrap>
      	  <LeftWrap mt="40px">
      	    <SectionPageTitle textAlign="left">{pageData.name}</SectionPageTitle>
            <PricingWrap>
              <FlexWrap alignItems="center" ml="0" mr="0">
              <CallPrice href="tel:386755-6449">Call for Pricing</CallPrice>
              </FlexWrap>
              <FlexWrap ml="0" mr="0"  className="financing-wrap">
                {pageData.rto && (
                  <FlexWrap ml="0" mr="0"><FinancingCheckIcon />&nbsp;&nbsp; RTO</FlexWrap>
                )}
                {pageData.financing && (
                  <FlexWrap ml="20px" mr="0"><FinancingCheckIcon />&nbsp;&nbsp; Financing</FlexWrap>
                )}
              </FlexWrap>
            </PricingWrap>
            <SectionDescription textAlign="left">
              <div className="h4">Description</div>
              <div dangerouslySetInnerHTML={{__html: pageData.description.childMarkdownRemark.html}} />
            </SectionDescription>
            <ProductButtons />
            <p>{pageData.pricingLabel}</p>
      	  </LeftWrap>
      	  <RightWrap mt="40px">
      	  	<ImageWrap>
      	  		<GatsbyImage image={getImage(pageData.image.gatsbyImageData)} alt={pageData.name} />
      	  		<SkuWrap>
								<StaticImage
									src="../images/sku.png"
					        placeholder="blurred"
					        formats={["AUTO", "WEBP", "AVIF"]}
					        alt="sku"
					      />
					      <div className="sku">
									sku: {pageData.sku}
								</div>
							</SkuWrap>
      	  	</ImageWrap>
            <DimensionsWrap>
              <div className="dimension">
                <div>
                  <WidthIcon />
                </div>
                <div>
                  <p className="label">Width</p>
                  <strong className="h5">{pageData.width}</strong>
                </div>
              </div>
              <div className="dimension">
                <div>
                  <LengthIcon />
                </div>
                <div>
                  <p className="label">Length</p>
                  <strong className="h5">{pageData.length}</strong>
                </div>
              </div>
              {pageData.category?.name !== "Gazebos" && pageData.subCategory?.name !== "Metal Barns" && (
                <div className="dimension">
                  <div>
                    <HeightIcon />
                  </div>
                  <div>
                    <p className="label">Height</p>
                    <strong className="h5">{pageData.height}</strong>
                  </div>
                </div>
              )}
            </DimensionsWrap>
      	  </RightWrap>
      	</HeroWrap>
        <FlexWrap mt="40px">
          <LeftWrap width="60%">
            <FeatureHeader>
              Standard Features
            </FeatureHeader>
            <Features>    
              <ul>
                {pageData.standardFeatures.map((item, i) => (
                  <li key={`standard-feature-${i}`}>
                    <p>{item.content}</p>
                  </li>
                ))}
              </ul>          
            </Features>
            {pageData.category.name === "Portable Buildings" && (
              <>
                <FeatureHeader>
                  Building Dimension & Starting Price
                </FeatureHeader>
                <div>{pageData.buildingDimensionDescription}</div>
                <FlexWrap justifyContent="start" mt="25px" mb="50px">
                  {pageData.buildingDimensionStartingPrice.map((dp, i) => (
                    <DimensionPrice key={`dimensionPrice-${i}`}>
                      {dp.content}
                    </DimensionPrice>
                  ))}
                </FlexWrap>
              </>
            )}
            
            <FeatureHeader>
              Options
            </FeatureHeader>
            <Features>
              <MarkdownContent mb="50px" className="markdown-content">
                <div dangerouslySetInnerHTML={{__html: pageData.options.childMarkdownRemark.html}} />
              </MarkdownContent>
            </Features>

            <FeatureHeader>
              Extra Features
            </FeatureHeader>
            <FeaturesWrap>
              <FeatureItem>
                <Features>
                    <ul>
                      {pageData.extraFeatures.map((ef, i)=> {
                        if (i < (pageData.extraFeatures.length/2)) {
                          return(
                            <li key={`extra-feature-${i}`}>
                              <p>{ef.content}</p>
                            </li>
                          )
                        } else {
                          return true;
                        }
                      })}
                    </ul>
                </Features>
              </FeatureItem>
              <FeatureItem>
                <Features>
                    <ul>
                      {pageData.extraFeatures.map((ef, i)=> {
                        if (i >= (pageData.extraFeatures.length/2)) {
                          return(
                            <li key={`extra-feature-${i}`}>
                              <p>{ef.content}</p>
                            </li>
                          )
                        } else {
                          return true;
                        }
                      })}
                    </ul>
                </Features>
              </FeatureItem>
            </FeaturesWrap>
            <FeatureHeader>
              Color Options
            </FeatureHeader>
            <p>{pageData.colorOptionsDescription}</p>
            <ColorOptions>
              <ColorOptionTabsWrap>
                <ColorOptionTab
                  active={colorOption === "siding" ? "true" : "false"}
                  onClick={()=>setColorOption("siding")}
                >
                  Siding Colors
                </ColorOptionTab>
                <ColorOptionTab
                  active={colorOption === "trim" ? "true" : "false"}
                  onClick={()=>setColorOption("trim")}
                  ml="20px"
                >
                  Trim Colors
                </ColorOptionTab>
              </ColorOptionTabsWrap>
              
              {colorOption === "siding" && (
                <ColorsWrap>
                  {pageData.sidingColors.map((sc, i) => (
                    <div className="color" key={`sidingColor-${i}`}>
                      <GatsbyImage image={getImage(sc.color.gatsbyImageData)} alt={sc.name} />
                      <p>{sc.name}</p>
                    </div>
                  ))}
                </ColorsWrap>
              )}
              {colorOption === "trim" && (
                <ColorsWrap>
                  {pageData.trimColors.map((tc, i) => (
                    <div className="color" key={`trimColor-${i}`}>
                      <GatsbyImage image={getImage(tc.color.gatsbyImageData)} alt={tc.name}/>
                      <p>{tc.name}</p>
                    </div>
                  ))}
                </ColorsWrap>
              )}
            </ColorOptions>
          </LeftWrap>
          <RightWrap width="38%">
            {(pageData.category.name === "Red Iron Buildings") ? <PanelCard /> : <WarrantyCard /> }
            <ApplyFinancingCard />
            <GetCustomQuoteForm location={location} />
          </RightWrap>
        </FlexWrap>
      </div>
      {/* <Section bgColor="#F7F5F5" mb="50px" pt="150px">
      	<div className="container">
      		<PostContent
            content={generateHTML(pageData.content.childMarkdownRemark.html)}
          />
      	</div>
      </Section> */}
    </Layout>
  )
}

export default ProductTemplate;

export const pageQuery = graphql`
  query ProductTemplateQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      metaTitle
      metaDescription
      category {
        name
      }
      subCategory {
        name
      }
      name
      url
      sku
      width
      length
      height
      image {
        gatsbyImageData(quality: 80)
        file {
          url
        }
      }
      startingPrice
      downPayment
      rto
      financing
      description {
        childMarkdownRemark {
          html
        }
        description
      }
      pricingLabel
      standardFeatures {
        content
      }
      buildingDimensionDescription
      buildingDimensionStartingPrice {
        content
      }
      options {
        childMarkdownRemark {
          html
        }
      }
      extraFeatures {
        content
      }
      colorOptionsDescription
      sidingColors {
        name
        color {
          gatsbyImageData(quality: 80)
        }
      }
      trimColors {
        name
        color {
          gatsbyImageData(quality: 80)
        }
      }
    }
  }
`