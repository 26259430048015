import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import PrimaryLinkButton from "../Button/PrimaryLinkButton";
import LineArrowRight from "../Icons/LineArrowRight"

const CardWrap = styled.div`
	padding: 40px 30px;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  margin-bottom: 72px;

  .title {
    font-size: 26px;
    font-family: "Eina03-Bold";
    line-height: 24px;
    margin: 20px 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
  p {
  	font-size: 16px;
  	line-height: 24px;
  	margin-bottom: 20px;
  }
`

const ApplyFinancingCard = () => (
	<CardWrap>
		<StaticImage
	    src="../../images/financing.png"
	    placeholder="blurred"
	    formats={["AUTO", "WEBP", "AVIF"]}
	    alt="limited warranty"
	  />
	  <h3 className="title">100% Financing</h3>
	  <p>Taking advantage of our financing service comes with its own list of benefits and features. Here are a few ways our finance program can help you:</p>
	  <Link to="/financing">
      <PrimaryLinkButton text="Apply Now" icon={<LineArrowRight />} />
    </Link>
	</CardWrap>
);

export default ApplyFinancingCard;