import React from 'react';

const LengthIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34.374" height="30.315" viewBox="0 0 34.374 30.315">
      <g id="Group_11074" data-name="Group 11074" transform="translate(0 -0.618)">
        <g id="Group_20" data-name="Group 20" transform="translate(0 0.618)">
          <g id="Group_15" data-name="Group 15">
            <g id="Path_32" data-name="Path 32" transform="translate(14.927 29.065)" fill="#fff">
              <path d="M -0.2042183429002762 -3.840789556503296 L -0.2042183429002762 -12.58476066589355 L 12.2867317199707 -19.6629638671875 L 12.2867317199707 -10.91900253295898 L -0.2042183429002762 -3.840789556503296 Z" stroke="none"/>
              <path d="M 11.78672122955322 -18.8049373626709 L 0.295771598815918 -12.29340076446533 L 0.295771598815918 -4.698816299438477 L 11.78672122955322 -11.21036243438721 L 11.78672122955322 -18.8049373626709 M 12.78672122955322 -20.52099609375 L 12.78672122955322 -10.62763690948486 L -0.704228401184082 -2.982757568359375 L -0.704228401184082 -12.87612724304199 L 12.78672122955322 -20.52099609375 Z" stroke="none" fill="#707070"/>
            </g>
            <g id="Path_33" data-name="Path 33" transform="translate(2.173 29.746)" fill="#fff">
              <path d="M 11.99900054931641 -4.52202320098877 L -0.4919394850730896 -11.60022640228271 L -0.4919394850730896 -20.73124504089355 L 4.795351028442383 -21.04766082763672 L 11.99900054931641 -13.35970592498779 L 11.99900054931641 -4.52202320098877 Z" stroke="none"/>
              <path d="M 4.590943813323975 -20.53454208374023 L 0.008060455322265625 -20.26028060913086 L 0.008060455322265625 -11.89158630371094 L 11.49900054931641 -5.380050659179688 L 11.49900054931641 -13.1620512008667 L 4.590943813323975 -20.53454208374023 M 4.999740600585938 -21.560791015625 L 12.49900054931641 -13.55735015869141 L 12.49900054931641 -3.663990020751953 L -0.9919395446777344 -11.30886077880859 L -0.9919395446777344 -21.20222091674805 L 4.999740600585938 -21.560791015625 Z" stroke="none" fill="#707070"/>
            </g>
            <g id="Path_34" data-name="Path 34" transform="translate(17.757 30.116)" fill="#fff">
              <path d="M -3.651599168777466 -13.35441112518311 L -9.949390411376953 -21.37519454956055 L 3.993096113204956 -29.46927261352539 L 10.54476070404053 -21.44512939453125 L -3.651599168777466 -13.35441112518311 Z" stroke="none"/>
              <path d="M 3.875429153442383 -28.82282257080078 L -9.200909614562988 -21.23157119750977 L -3.524600028991699 -14.00229835510254 L 9.783574104309082 -21.58682632446289 L 3.875429153442383 -28.82282257080078 M 4.11076545715332 -30.1157341003418 L 11.30594539642334 -21.30344390869141 L -3.778604984283447 -12.70653533935547 L -10.69787502288818 -21.51882553100586 L 4.11076545715332 -30.1157341003418 Z" stroke="none" fill="#707070"/>
            </g>
            <path id="Path_35" data-name="Path 35" d="M-11.185-22.616,3.937-31.372l6.745-.45L-4.28-23.02Z" transform="translate(11.185 31.821)" fill="#d0d0d0"/>
            <g id="Path_36" data-name="Path 36" transform="translate(5.371 13.402)" fill="#fff">
              <path d="M 4.304094314575195 9.112177848815918 L -0.09228566288948059 6.547621250152588 L -0.09228566288948059 0.06046134606003761 L 4.304094314575195 2.625017642974854 L 4.304094314575195 9.112177848815918 Z" stroke="none"/>
              <path d="M 0.4077143669128418 0.9309778213500977 L 0.4077143669128418 6.260438919067383 L 3.804094314575195 8.241661071777344 L 3.804094314575195 2.912199974060059 L 0.4077143669128418 0.9309778213500977 M -0.5922856330871582 -0.8100605010986328 L 4.804094314575195 2.33782958984375 L 4.804094314575195 9.982699394226074 L -0.5922856330871582 6.834809303283691 L -0.5922856330871582 -0.8100605010986328 Z" stroke="none" fill="#707070"/>
            </g>
          </g>
        </g>
        <g id="Group_8711" data-name="Group 8711" transform="translate(15.854 31.803) rotate(-121)">
          <path id="Path_39" data-name="Path 39" d="M.347,0,0,2.805,2.679,3.917" transform="translate(4.957 1.958) rotate(120)" fill="none" stroke="#fe0000" stroke-width="1"/>
          <path id="Path_40" data-name="Path 40" d="M.377,3.978,0,1.1,2.6,0" transform="translate(1.301 21.611) rotate(-120)" fill="none" stroke="#fe0000" stroke-width="1"/>
          <path id="Path_41" data-name="Path 41" d="M.271,0,0,20.451" transform="translate(2.341 0.585)" fill="none" stroke="#fe0000" stroke-width="1"/>
        </g>
      </g>
    </svg>


  )
}
export default LengthIcon;