import React from 'react';

const FinancingCheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.247" height="31.247" viewBox="0 0 31.247 31.247">
    <g id="Group_9008" data-name="Group 9008" transform="translate(0.579 0.579)">
      <path id="Path_19219" data-name="Path 19219" d="M12.986,0A12.986,12.986,0,1,1,0,12.986" transform="matrix(-0.174, 0.985, -0.985, -0.174, 30.088, 4.51)" fill="#fff" stroke="#938888" stroke-width="1"/>
      <path id="Path_19218" data-name="Path 19218" d="M10945.531,326.994l4.785,4.455,2.489-2.451,10.012-9.857" transform="translate(-10935.357 -313.173)" fill="none" stroke="#e43d3c" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
    </g>
  </svg>
);

export default FinancingCheckIcon
