import React from 'react'

const WidthIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.682" height="31.573" viewBox="0 0 33.682 31.573">
      <g id="Group_11075" data-name="Group 11075" transform="translate(0.057 0.355)">
        <g id="Group_20" data-name="Group 20" transform="translate(4.625 -0.355)">
          <g id="Group_15" data-name="Group 15">
            <g id="Path_32" data-name="Path 32" transform="translate(14.896 29.047)" fill="#fff">
              <path d="M -0.2042242437601089 -3.878585338592529 L -0.2042242437601089 -12.60123729705811 L 12.25765609741211 -19.66296195983887 L 12.25765609741211 -10.94031810760498 L -0.2042242437601089 -3.878585338592529 Z" stroke="none"/>
              <path d="M 11.75765609741211 -18.80493545532227 L 0.2957754135131836 -12.30987739562988 L 0.2957754135131836 -4.736612319946289 L 11.75765609741211 -11.23167896270752 L 11.75765609741211 -18.80493545532227 M 12.75765609741211 -20.52099227905273 L 12.75765609741211 -10.64895248413086 L -0.7042245864868164 -3.020553588867188 L -0.7042245864868164 -12.89260292053223 L 12.75765609741211 -20.52099227905273 Z" stroke="none" fill="#707070"/>
            </g>
            <g id="Path_33" data-name="Path 33" transform="translate(2.171 29.729)" fill="#fff">
              <path d="M 11.96993541717529 -4.560584545135498 L -0.4919449388980865 -11.62231731414795 L -0.4919449388980865 -20.73201751708984 L 4.782435894012451 -21.04766273498535 L 11.96993541717529 -13.3769474029541 L 11.96993541717529 -4.560584545135498 Z" stroke="none"/>
              <path d="M 4.578029155731201 -20.53454208374023 L 0.008054733276367188 -20.26105308532715 L 0.008054733276367188 -11.91367816925049 L 11.46993541717529 -5.418611526489258 L 11.46993541717529 -13.17929363250732 L 4.578029155731201 -20.53454208374023 M 4.986824989318848 -21.56079292297363 L 12.46993541717529 -13.57459259033203 L 12.46993541717529 -3.702552795410156 L -0.9919452667236328 -11.33095264434814 L -0.9919452667236328 -21.20299339294434 L 4.986824989318848 -21.56079292297363 Z" stroke="none" fill="#707070"/>
            </g>
            <g id="Path_34" data-name="Path 34" transform="translate(17.741 30.116)" fill="#fff">
              <path d="M -3.666507482528687 -13.39192676544189 L -9.94938850402832 -21.39371871948242 L 3.961187601089478 -29.46926879882812 L 10.49734210968018 -21.46411323547363 L -3.666507482528687 -13.39192676544189 Z" stroke="none"/>
              <path d="M 3.843520402908325 -28.82281684875488 L -9.200907707214355 -21.25009536743164 L -3.53950834274292 -14.03981399536133 L 9.736156463623047 -21.60581207275391 L 3.843520402908325 -28.82281684875488 M 4.078856945037842 -30.11573028564453 L 11.25852680206299 -21.32242965698242 L -3.793513298034668 -12.74404907226562 L -10.69787311553955 -21.53734970092773 L 4.078856945037842 -30.11573028564453 Z" stroke="none" fill="#707070"/>
            </g>
            <path id="Path_35" data-name="Path 35" d="M-11.185-22.636,3.9-31.373l6.731-.449-14.93,8.783Z" transform="translate(11.185 31.821)" fill="#d0d0d0"/>
            <g id="Path_36" data-name="Path 36" transform="translate(5.361 13.374)" fill="#fff">
              <path d="M 4.292470455169678 9.088926315307617 L -0.09228973835706711 6.531157970428467 L -0.09228973835706711 0.06046248599886894 L 4.292470455169678 2.618239164352417 L 4.292470455169678 9.088926315307617 Z" stroke="none"/>
              <path d="M 0.4077103137969971 0.9309787750244141 L 0.4077103137969971 6.243968963623047 L 3.792470216751099 8.218405723571777 L 3.792470216751099 2.905421733856201 L 0.4077103137969971 0.9309787750244141 M -0.592289924621582 -0.8100595474243164 L 4.792470455169678 2.331050872802734 L 4.792470455169678 9.959441184997559 L -0.592289924621582 6.81834077835083 L -0.592289924621582 -0.8100595474243164 Z" stroke="none" fill="#707070"/>
            </g>
          </g>
        </g>
        <g id="Group_8711" data-name="Group 8711" transform="translate(0 21.591) rotate(-61)">
          <path id="Path_39" data-name="Path 39" d="M.333,0,.005,2.916l2.64,1.167" transform="translate(4.859 2.005) rotate(120)" fill="none" stroke="#fe0000" stroke-width="1"/>
          <path id="Path_40" data-name="Path 40" d="M.307,4.039,0,1.169,2.687,0" transform="translate(1.498 21.502) rotate(-120)" fill="none" stroke="#fe0000" stroke-width="1"/>
          <path id="Path_41" data-name="Path 41" d="M0,0,.18,20.315" transform="translate(2.419 0.582)" fill="none" stroke="#fe0000" stroke-width="1"/>
        </g>
      </g>
    </svg>

  )
}
export default WidthIcon;