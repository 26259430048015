import React from 'react';

const HeightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.791" height="26.137" viewBox="0 0 32.791 26.137">
      <g id="Group_11073" data-name="Group 11073" transform="translate(0.323 0.588)">
        <g id="Group_20" data-name="Group 20" transform="translate(4)">
          <g id="Group_15" data-name="Group 15">
            <g id="Path_32" data-name="Path 32" transform="translate(14.636 28.891)" fill="#fff">
              <path d="M -0.2042244523763657 -4.199425220489502 L -0.2042244523763657 -12.74108695983887 L 12.01085567474365 -19.66296005249023 L 12.01085567474365 -11.12130832672119 L -0.2042244523763657 -4.199425220489502 Z" stroke="none"/>
              <path d="M 11.51085567474365 -18.80493354797363 L 0.2957754135131836 -12.44972610473633 L 0.2957754135131836 -5.057451248168945 L 11.51085567474365 -11.41266822814941 L 11.51085567474365 -18.80493354797363 M 12.51085567474365 -20.52099227905273 L 12.51085567474365 -10.82994270324707 L -0.7042245864868164 -3.341392517089844 L -0.7042245864868164 -13.03245258331299 L 12.51085567474365 -20.52099227905273 Z" stroke="none" fill="#707070"/>
            </g>
            <g id="Path_33" data-name="Path 33" transform="translate(2.149 29.579)" fill="#fff">
              <path d="M 11.72313499450684 -4.887985229492188 L -0.4919446706771851 -11.80985832214355 L -0.4919446706771851 -20.73857879638672 L 4.672826290130615 -21.04766464233398 L 11.72313499450684 -13.5233678817749 L 11.72313499450684 -4.887985229492188 Z" stroke="none"/>
              <path d="M 4.468419551849365 -20.53454399108887 L 0.008055686950683594 -20.26761245727539 L 0.008055686950683594 -12.10121917724609 L 11.22313499450684 -5.746011734008789 L 11.22313499450684 -13.32571411132812 L 4.468419551849365 -20.53454399108887 M 4.877215385437012 -21.56079292297363 L 12.22313499450684 -13.72101306915283 L 12.22313499450684 -4.029953002929688 L -0.9919443130493164 -11.51849269866943 L -0.9919443130493164 -21.20955276489258 L 4.877215385437012 -21.56079292297363 Z" stroke="none" fill="#707070"/>
            </g>
            <g id="Path_34" data-name="Path 34" transform="translate(17.612 30.116)" fill="#fff">
              <path d="M -3.793082714080811 -13.71040725708008 L -9.949383735656738 -21.55098915100098 L 3.690282583236694 -29.46926879882812 L 10.09481620788574 -21.62532424926758 L -3.793082714080811 -13.71040725708008 Z" stroke="none"/>
              <path d="M 3.572613000869751 -28.82281875610352 L -9.200913429260254 -21.4073657989502 L -3.666086435317993 -14.35829544067383 L 9.333619117736816 -21.76702308654785 L 3.572613000869751 -28.82281875610352 M 3.807949542999268 -30.11573028564453 L 10.85598945617676 -21.48364067077637 L -3.920090198516846 -13.06253051757812 L -10.69788074493408 -21.69462013244629 L 3.807949542999268 -30.11573028564453 Z" stroke="none" fill="#707070"/>
            </g>
            <path id="Path_35" data-name="Path 35" d="M-11.185-22.8,3.628-31.381l6.608-.441L-4.421-23.2Z" transform="translate(11.185 31.821)" fill="#d0d0d0"/>
            <g id="Path_36" data-name="Path 36" transform="translate(5.273 13.144)" fill="#fff">
              <path d="M 4.193750381469727 8.8914794921875 L -0.0922897681593895 6.391292095184326 L -0.0922897681593895 0.06046193838119507 L 4.193750381469727 2.560649156570435 L 4.193750381469727 8.8914794921875 Z" stroke="none"/>
              <path d="M 0.4077103137969971 0.9309768676757812 L 0.4077103137969971 6.104109764099121 L 3.693750143051147 8.020964622497559 L 3.693750143051147 2.847831726074219 L 0.4077103137969971 0.9309768676757812 M -0.592289924621582 -0.8100595474243164 L 4.693750381469727 2.273460865020752 L 4.693750381469727 9.762001037597656 L -0.592289924621582 6.678481101989746 L -0.592289924621582 -0.8100595474243164 Z" stroke="none" fill="#707070"/>
            </g>
          </g>
        </g>
        <g id="Group_8711" data-name="Group 8711" transform="translate(4.688 21.271) rotate(-180)">
          <path id="Path_39" data-name="Path 39" d="M.339,0,0,2.8,2.6,3.913" transform="translate(4.688 1.957) rotate(120)" fill="none" stroke="#fe0000" stroke-width="1"/>
          <path id="Path_40" data-name="Path 40" d="M.339,3.913,0,1.109,2.6,0" transform="translate(1.299 21.16) rotate(-120)" fill="none" stroke="#fe0000" stroke-width="1"/>
          <path id="Path_41" data-name="Path 41" d="M0,0V20" transform="translate(2.344 0.584)" fill="none" stroke="#fe0000" stroke-width="1"/>
        </g>
      </g>
    </svg>

  )
}
export default HeightIcon;